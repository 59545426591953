import React, { useEffect, useRef } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import {
	FaqItemMultiLang,
	Language,
	News,
	PaginationByPage,
	Post,
	Sport,
	SportId,
	StatSeason,
} from '@ui-kit/main-api-types';
import {
	DropdownItem,
	PROJECTS,
} from '@ui-kit/main-frontend-ui-kit/dist/src/utils/types';
import { StatMatch } from '@ui-kit/main-int-api-types';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useTranslations } from 'next-intl';
import LazyHydrationHOC from 'react-lazy-hydration';

import styles from '../../styles/Home.module.scss';

const DugoutPlayer = dynamic(() => import('@components/Dugout-player'), {
	ssr: false,
});

const NoSSRHOC = dynamic(
	() =>
		import('@ui-kit/main-frontend-ui-kit/dist/src/components/NoSSRHOC').then(
			m => m.NoSsr,
		),
	{
		ssr: false,
	},
);
const BannerAlternative = dynamic(
	() => import('@components/Banner-alternative'),
	{
		ssr: false,
	},
);

import { initializeApollo } from '@apollo-client';
import {
	getSeasonForWidget,
	getStatisticWidgetTopTournaments,
} from '@store/stat_unification/actions';
import teaserQueries from '@store/teaser/queries';
import { SOCIAL_SHARING_LINKS, TRIBUNA_COM_URL } from '@utils/constants';
import {
	getFaqItemsAndSeoText,
	getMainHreflangs,
	getServerSidePropsHOF,
	sendAnalytics,
	topLeaguesForLang,
} from '@utils/helpers';
import { LANG } from '@utils/types';

const MatchesTeaser = dynamic(() => import('@components/Matches-teaser'), {
	ssr: true,
});
const PostsList = dynamic(
	() => import('@components/Post-unification/PostsList'),
	{
		ssr: true,
	},
);
const TopPostHOC = dynamic(() => import('@components/TopPostHOC'), {
	ssr: true,
});
const AllNewsWidget = dynamic(
	() =>
		import(
			'@ui-kit/main-frontend-ui-kit/dist/src/components/NewsList/AllNewsWidget'
		).then(m => m.AllNewsWidget),
	{
		ssr: true,
	},
);
const StatisticWidget = dynamic(
	() =>
		import(
			'@ui-kit/statistics-ui-kit/dist/src/components/StatisticWidget'
		).then(m => m.StatisticWidget),
	{
		ssr: true,
	},
);
const SeoNews = dynamic(
	() =>
		import('@ui-kit/main-frontend-ui-kit/dist/src/components/SeoNews').then(
			m => m.SeoNews,
		),
	{
		ssr: true,
	},
);

import { getNewsList } from '@store/news_unification/actions';
import { isMobileDeviceMDNCheck } from '@utils/tools';
import {
	getPopularNotEditorialPostList,
	getPostFeed,
	getTopPost,
} from '@store/post_unification/actions';
import { getSport } from '@store/app_unificated/actions';
import { getFaqJsonLd } from '@utils/json-ld';
import { getMainPageJsonLd } from '@utils/json-ld-unificated';

interface IHomePageProps {
	sport: Sport;
	posts: Post[];
	newsList: News[];
	seoNews?: News[];
	seoText: string[];
	seoNewsLastPage?: number;
	topPost: Post;
	faqItems: FaqItemMultiLang[];
	popularMatches: StatMatch[];
	popularNotEditorialPosts: Post[];
	tournamentsList: DropdownItem[];
	initialSeason: StatSeason;
}

export const HomePage: React.FC<IHomePageProps> = ({
	sport,
	newsList,
	seoNews,
	seoNewsLastPage,
	posts,
	topPost,
	popularMatches,
	popularNotEditorialPosts,
	tournamentsList,
	initialSeason,
	seoText,
	faqItems,
}) => {
	const t = useTranslations();
	const { locale } = useRouter();
	const currentPage = useRef(1);
	const currentSeoNewsPage = useRef(1);

	const seo = sport?.seo;

	const getMorePosts = async () => {
		currentPage.current += 1;

		const postsQuery = await getPostFeed(
			{ main: true, page: currentPage.current, pageSize: 15 },
			locale,
			true,
		);

		const { posts: newPosts, paginationInfo } = postsQuery || {};
		return {
			posts: newPosts,
			isLastPage: paginationInfo?.currentPage >= paginationInfo?.lastPage,
		};
	};

	const getMoreSeoNews = async () => {
		currentSeoNewsPage.current += 1;

		const newsNews = await getNewsList({
			page: currentSeoNewsPage.current,
			pageSize: 10,
			locale,
			isMain: true,
			isSeoContent: true,
			isMainInSection: true,
			lang: locale?.toUpperCase() as Language,
		});
		const { newsList, paginationInfo } = newsNews || {};

		return {
			news: newsList,
			isLastPage: currentSeoNewsPage.current >= paginationInfo?.lastPage,
			page: paginationInfo?.currentPage,
		};
	};

	useEffect(() => {
		sendAnalytics({
			category: 'viewport',
			name: 'view',
			label: 'widget_bookmaker',
		});
	}, []);

	const topPostPhoto = topPost?.topPhoto?.url || topPost?.mainPhoto?.url;

	const shouldShowFAQ = !!faqItems?.length;
	const faqJsonLd = getFaqJsonLd({
		faqItems,
		locale: locale as LANG,
	});

	const h1 = seo?.h1?.[locale] || sport?.name?.[locale];
	const title = seo?.title?.[locale] || sport?.name?.[locale];
	const description = seo?.description?.[locale] || sport?.name?.[locale];
	const canonical = seo?.canonical?.[locale]
		? seo?.canonical?.[locale]
		: locale !== LANG.en
			? `https://${locale}.tribuna.com/`
			: 'https://tribuna.com/';

	const hrefLangs = getMainHreflangs();

	return (
		<>
			<Head>
				<title>{title}</title>
				<meta name="description" content={description} />
				<link rel="icon" href="/favicon/favicon-1.ico" />
				<link rel="canonical" href={canonical} />

				{Object.keys(hrefLangs)?.map(lang => (
					<link
						rel="alternate"
						hrefLang={lang}
						href={hrefLangs[lang]}
						key={`${lang}-alternate`}
					/>
				))}

				<link
					rel="preload"
					href={`${topPostPhoto}?width=750&quality=70`}
					as="image"
				/>
				<meta property="og:url" content={canonical} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				<meta property="og:image" content={SOCIAL_SHARING_LINKS.generalLink} />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				<meta name="twitter:image" content={SOCIAL_SHARING_LINKS.twitterLink} />
				<meta property="og:locale" content={locale} />
				<meta
					name="robots"
					content="max-image-preview:large,max-video-preview:15"
				/>
				<meta name="twitter:card" content="summary_large_image" />
			</Head>
			<Script
				id="mainPageJson"
				strategy="beforeInteractive"
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: JSON.stringify(
						getMainPageJsonLd({
							posts: [topPost, ...posts, ...popularNotEditorialPosts],
							locale,
							news: [...newsList, ...seoNews],
							matches: popularMatches,
							season: initialSeason,
						}),
					),
				}}
			/>

			{shouldShowFAQ && (
				<Script
					id="faqJsonLd"
					strategy="beforeInteractive"
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(faqJsonLd),
					}}
				/>
			)}

			<div className={styles['home-page']}>
				<div className={styles['home-page__match-teaser-wrapper']}>
					<MatchesTeaser popularMatches={popularMatches || []} h1={h1} />
				</div>
				<div className={styles['home-page__content-wrapper']}>
					<div className={styles['home-page__left-column']}>
						{!!topPost?.id && (
							<div className={styles['home-page__top-post']}>
								<TopPostHOC post={topPost} />
							</div>
						)}
						<div className={styles['home-page__news-and-blogs-wrapper']}>
							<div className={styles['home-page__news-container']}>
								<LazyHydrationHOC whenVisible>
									<AllNewsWidget
										newsDataTest="news-section"
										headerDataTest="news-section-header"
										buttonDataTest="news-section-button-all"
										newsList={newsList}
										isItMainPage
										isItSportsPage
										linkToAllNews={`${TRIBUNA_COM_URL}${locale}/news/`}
										locale={locale}
										localeLink={`${TRIBUNA_COM_URL}${locale}`}
										projectLang={locale}
										project={PROJECTS.INT}
									/>
								</LazyHydrationHOC>
								<NoSSRHOC hideFromBot>
									{!isMobileDeviceMDNCheck() && (
										<div className={styles['home-page--sticky']}>
											<BannerAlternative id={'90live_1Left'} />
										</div>
									)}
								</NoSSRHOC>
							</div>
							<div className={styles['home-page__blogs-container']}>
								<LazyHydrationHOC whenVisible>
									<PostsList
										initialPosts={posts}
										getMorePosts={getMorePosts}
										showBlog={false}
										displayPostsWidget
										displayBookieWidget
										popularNotEditorialPosts={popularNotEditorialPosts}
										displayAdvertisement={false}
										isItMainPage
										seoText={seoText}
										faq={faqItems}
									/>
								</LazyHydrationHOC>
							</div>
						</div>
					</div>
					<div className={styles['home-page__right-column']}>
						<NoSSRHOC hideFromBot>
							{!isMobileDeviceMDNCheck() && <DugoutPlayer isMobile={false} />}
						</NoSSRHOC>
						<LazyHydrationHOC whenVisible>
							<StatisticWidget
								tournaments={tournamentsList}
								initialSeason={initialSeason}
								getSeasonRequest={(id: string[]) =>
									getSeasonForWidget(id, 5, locale as LANG)
								}
								playersButtonUrlPostfix={'stats/'}
							/>
						</LazyHydrationHOC>
						<LazyHydrationHOC whenVisible>
							{seoNews?.length > 0 && (
								<SeoNews
									initialNews={seoNews}
									getMoreSeoNews={getMoreSeoNews}
									lastPage={seoNewsLastPage}
									projectLang={locale}
									locale={locale}
									localeLink={`${TRIBUNA_COM_URL}${locale}`}
								/>
							)}
						</LazyHydrationHOC>
						<NoSSRHOC hideFromBot>
							{!isMobileDeviceMDNCheck() && (
								<div className={styles['home-page--sticky']}>
									<BannerAlternative id={'right-top'} />
								</div>
							)}
						</NoSSRHOC>
					</div>
				</div>
			</div>
		</>
	);
};

export const getServerSideProps: GetServerSideProps = ctx =>
	getServerSidePropsHOF(ctx)(getServerSidePropsMain);

const getServerSidePropsMain: GetServerSideProps = async ({ locale, req }) => {
	const language = (locale as string).toUpperCase() as Language;
	const client = initializeApollo();

	const sportQuery = getSport({ sportType: SportId.Football });

	const newsQuery = getNewsList({
		page: 1,
		pageSize: 15,
		lang: language,
		isMain: true,
		isMainInSection: true,
		isRegularContent: true,
		locale,
	});
	const postsQuery = getPostFeed({ main: true, page: 1, pageSize: 15 }, locale);
	const seoNewsQuery = getNewsList({
		page: 1,
		pageSize: 10,
		lang: language,
		locale,
		isMain: true,
		isSeoContent: true,
		isMainInSection: true,
	});
	const popularNotEditorialPostsQuery = getPopularNotEditorialPostList({
		locale,
	});
	const topPostQuery = getTopPost({ locale });
	const topLeagues = topLeaguesForLang({ lang: locale });
	const matchQueryQuery = client.query({
		query: teaserQueries.getMatches,
		variables: {
			id: topLeagues?.[0].id,
		},
	});
	const getStatisticsWidgetData = async () => {
		const tournaments = await getStatisticWidgetTopTournaments(locale as LANG);
		const tournamentsList: DropdownItem[] = tournaments?.map(tournament => ({
			id: tournament?.id,
			code: tournament?.currentSeason?.id,
			name: tournament?.name,
			text: tournament?.name,
		}));

		const initialSeason = await getSeasonForWidget(
			[tournaments?.[0]?.currentSeason?.id],
			5,
			locale as LANG,
		);

		return { tournamentsList, initialSeason: initialSeason };
	};

	const settledPromises = await Promise.allSettled([
		sportQuery,
		postsQuery,
		popularNotEditorialPostsQuery,
		topPostQuery,
		newsQuery,
		seoNewsQuery,
		matchQueryQuery,
		getStatisticsWidgetData(),
	]);

	const [
		sport,
		postsQueryResult,
		popularNotEditorialPosts,
		topPost,
		newsQueryResult,
		seoNewsQueryResult,
		matchQueryResult,
		statisticsWidgetData,
	] = settledPromises?.map(promise => promise?.['value']) as [
		Sport,
		{ posts: Post[]; paginationInfo: PaginationByPage },
		Post[],
		Post,
		{
			newsList: News[];
			paginationInfo: PaginationByPage;
		},
		{
			newsList: News[];
			paginationInfo: PaginationByPage;
		},
		ApolloQueryResult<any>,
		{
			tournamentsList: DropdownItem[];
			initialSeason: StatSeason;
		},
	];

	const newsList = newsQueryResult?.newsList || [];
	const seoNews = seoNewsQueryResult?.newsList || [];
	const seoNewsLastPage = seoNewsQueryResult?.paginationInfo?.lastPage || null;

	const teaser = matchQueryResult?.data?.tournament?.currentSeason?.teaser;
	const popularMatches = [
		...(teaser?.last || []),
		...(teaser?.current || []),
		...(teaser?.next || []),
	];
	const { posts } = postsQueryResult || {};
	const { tournamentsList, initialSeason } = statisticsWidgetData || {};

	const { faqItems, seoText } = await getFaqItemsAndSeoText(
		sport?.seo,
		locale as LANG,
	);

	const props: IHomePageProps = {
		posts: posts || [],
		sport: sport || ({} as Sport),
		newsList,
		seoNews,
		seoNewsLastPage,
		topPost,
		popularMatches,
		popularNotEditorialPosts: popularNotEditorialPosts || [],
		initialSeason: initialSeason || null,
		tournamentsList: tournamentsList || null,
		seoText,
		faqItems,
	};

	return {
		props,
	};
};

export default HomePage;
